.footer__wrapper {
    background-color: black;
}

.footer {
    text-align: baseline;
}

.footer__button {
    font-family: Quicksand;
    border: 1px solid $primary;
    font-size: 0.9rem!important;
    color: $light;
}

.footer__text {
    font-family: Quicksand;
    font-size: 0.9rem;
    color: $light;
}