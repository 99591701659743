@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&family=Roboto:wght@400;500&display=swap');

// functions
@import "../../node_modules/bootstrap/scss/functions";

// redefine variables not dependent on anything else

// global variables
$enable-negative-margins: true;

// theme color variables
$primary:       #0065F9;
$secondary:     #F99400;
$danger:        #CE0A1C;
$light:         #F2F1EF;
$dark:          #1a1a1a;

// font family
$font-family-base: "Roboto";

//Links

$link-color:                              $light;
$link-decoration:                         none;

// variables and mixin files
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// anything that depends on variables/mixins file defined below

//Offcanvas
$offcanvas-padding-y:               1rem;
$offcanvas-padding-x:               1rem;
$offcanvas-horizontal-width:        250px;
$offcanvas-border-color:            $primary;
$offcanvas-border-width:            0;
$offcanvas-bg-color:                $light;


//Carousel
$carousel-indicator-active-bg:       $secondary;
$carousel-caption-padding-y:         0;
$carousel-caption-width:             90%;

//add colors to theme
$custom-colors: (
    "primary-dark": #004EC1,
    "primary-light": #A8CBFF,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// import all of bootstrap sass
@import "../../node_modules/bootstrap/scss/bootstrap";

//Animate CSS
@import "../../node_modules/animate.css/animate.min.css";

:root {
    --animate-delay: 0.2s;
  }

// Custom CSS

@import "customNavbar";
@import "slides";
@import "features";
@import "baris__ailesi";
@import "services";
@import "kurumsal";
@import "footer";
@import "modal";
@import "pages";

//Fonts
