.kurumsal__wrapper {
    background-color: $dark;
}

.kurumsal__title {
    font-family: "Quicksand";
    color: $light;
    font-size: 1.5rem;
    margin: 0;
}

.kurumsal__line {
    border-top: 1px solid $secondary;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.contact__list {
    font-family: "Quicksand";
    color: $light;
    margin: 0;
}

.contact__list__icon {
    fill: $primary;
    max-height: 20px;
    max-width: 20px;
}

.kurumsal__logo {
    fill: black;
}

.social__list {
    text-decoration: none!important;
    font-family: "Quicksand";
    font-size: 1rem;
    color: $light;
    margin: 0;
}

.social__list__icon {
    fill: $primary;
    max-height: 20px;
    max-width: 20px;
}