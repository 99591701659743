.features-title {
    font-weight: 400;
    font-size: 1.4rem;
}

.features-title:after {
    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 80%; /* Change this to whatever width you want. */
    padding-top: 0.3rem; /* This creates some space between the element and the border. */
    border-bottom: 1px solid $secondary; /* This creates the border. Replace black with whatever color you want. */
}

.features__image {
    max-height: 60px;
}

.features__text {
    font-family: "Quicksand";
    font-size: 0.8rem;
    text-align: center;
}

@media (min-width: 768px) {
    .features-title {
        font-size: 2.4rem;
    }
    .features__image {
      max-height: 100px;
    }
    .features__text {
        font-size: 1.2rem;
    }
  }