.page-title {
    font-weight: 400;
    font-size: 1.4rem;
}

.page-title:after {
    content: ""; /* This is necessary for the pseudo element to work. */ 
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 80%; /* Change this to whatever width you want. */
    padding-top: 0.3rem; /* This creates some space between the element and the border. */
    border-bottom: 1px solid $secondary; /* This creates the border. Replace black with whatever color you want. */
}

@media (min-width: 768px) {
    .page-title {
        font-size: 2rem;
    }
  }

.article__wrapper {
    background-color: white;
    z-index: -1;
}

article {
    font-weight: 400;
    margin-bottom: 0;
    padding-bottom: 0;
}

.second__title {
    color: black;
    font-weight: 400;
}

.kadromuz__wrapper {
    border: 1px solid $secondary;
}

.card__body2 {
    padding: 0;
}

.isim {
    font-family: "Quicksand";
    font-size: 1.1rem;
    margin-top: 1rem;
}

.list {
    list-style-image: url('../img/icons/chevron-right.svg');
}

.accordion-button {
    color: $primary;
    font-size: 1.1rem;
}

.gallery__text {
    font-family: "Quicksand";
    font-size: 1.2rem;
    margin-top: 0.5rem;
    color: $primary;
}

.iletisim{
    z-index: 10;
}

.map-responsive{
    overflow:hidden;
    padding-bottom:50%;
    position:relative;
    height:0;
    border: 2px solid $primary;
}

.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.kvk__button {
    background-color: $primary;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.orange__border {
    border: 1px solid $secondary;
}

.article__title {
    font-family: "Quicksand";
    color: $secondary;
    font-size: 1.3rem;
}

.contact__list__text {
    text-decoration: none;
    color: black;
}

.home__cover {
    background-image: url("../img/barisailesibg.jpg?as=webp"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    height: 50vh; /* You must set a specified height */
    background-position: center center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  .home__cover__text {
      font-family: "Quicksand";
      color: white;
      font-size: clamp(1.5rem, 7vw, 2.6rem); 
  }