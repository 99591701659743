.contact__strip {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,78,193,1) 0%, rgba(0,101,249,1) 100%);
}

.contact__strip__icon {
    max-height: 14px;
    max-width: 14px;
    fill: white;
}

.contact__strip__text {
    font-family: 'Quicksand';
    font-size: 0.7rem;
    color: $light;
}

.lang__button__mobile {
    border-radius: 1rem;
    border: 1px solid white;
    font-family: 'Quicksand';
    font-weight: 500;
    font-size: 0.7rem;
    text-decoration: none;
    color: white;
    &:hover {
        background-color: $secondary!important;
        color: white;
        transition: all 0.1s ease;
    }
}

.logo__menu__mobile__wrapper {
    background-color: white;
}

.logo {
    max-height: 70px;
}

.menu {
    fill: $primary;
    height: 40px;
    width: 40px;
    background-color: white;
    border: none;
    &:hover {
        fill: $secondary;
        transition: all 0.1s ease;
    }
}

.dropdown-menu {
    background-color: white;
}

.navbar__mobile__link {
    color: $primary;
    font-weight: 500;
    &:hover {
        color: $secondary;
    }
}

.topbar__contact {
    font-family: "Quicksand";
    font-weight: 500;
    font-size: 1rem;
}

.topbar__contact__icon {
    height: 16px;
    max-width: 16px;
    fill: $primary;
}

.lang__button__desktop {
    border-radius: 1rem;
    border: 1px solid white;
    font-family: 'Quicksand';
    font-weight: 500;
    font-size: 0.8rem;
    text-decoration: none;
    color: white;
    &:hover {
        background-color: $secondary!important;
        color: white;
        transition: all 0.1s ease;
    }
}

.navbar__desktop__wrapper {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,78,193,1) 0%, rgba(0,101,249,1) 50%, rgba(0,78,193,1) 100%);
}

.navbar__desktop__link {
    color: white;
    font-weight: 400;
    font-size: 15px;
    &:hover{
        color: $secondary;
    }
}

.navbar__desktop__dropdown__item {
    color: $primary;
    &:hover {
        color: $secondary;
    }
}