.slides {
    background: #0F2027;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: calc(100vh - 129px);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
}

.slide__header {
    font-size: 1.4rem;
    font-family: "Quicksand";
    text-shadow: 2px 2px black;
}

.slide__text {
    font-size: 1rem;
    font-weight: 400;
}

.slide1 {
    background-image: url("../img/mobileSlide2.jpg?as=webp");
}
.slide3 {
    background-image: url("../img/mobileSlide3.jpg?as=webp");
}
.slide4 {
    background-image: url("../img/mobileSlide4.jpg?as=webp");
}
.slide5 {
    background-image: url("../img/mobileSlide5.jpg?as=webp");
}

.slide__button {
    border-radius: 0;
    border: 2px solid $primary;
    font-family: 'Quicksand';
    font-size: 1rem;
    text-decoration: none;
    color: white;
    background: rgba(black, 0.6);
    &:hover, :active {
        background-color: $secondary;
        border: 2px solid $secondary;
        color: white;
        transition: all 0.2s ease;
    }
}

@include media-breakpoint-up(md) {
    .slides {
        height: calc(100vh - 83px);
    }
    .slide1 {
        background-image: url("../img/desktopSlide2.jpg?as=webp");
    }
    .slide3 {
        background-image: url("../img/desktopSlide3.jpg?as=webp");
    }
    .slide4 {
        background-image: url("../img/desktopSlide4.jpg?as=webp");
    }
    .slide5 {
        background-image: url("../img/desktopSlide5.jpg?as=webp");
    }
    
 }

 @include media-breakpoint-up(xl) {
    .slides {
        height: calc(100vh - 120px);
    }
    .slide__header {
        font-size: 2rem;
    }
    .slide__button {
        font-size: 1.1rem;
    }
    .slide__text {
        font-size: 1.8rem;
    }
 }