.section-title {
  font-weight: 400;
  font-size: 1.4rem;
}

.section-title:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 80%; /* Change this to whatever width you want. */
  padding-top: 0.1rem; /* This creates some space between the element and the border. */
  border-bottom: 1px solid $secondary; /* This creates the border. Replace black with whatever color you want. */
}

@media (min-width: 768px) {
  .section-title {
    font-size: 2.4rem;
  }
}

.card__body {
  background-color: $light;
  padding: 0;
  border: 1px solid $primary;
  transition: 0.4s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  fill: #016cde;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 78, 193, 1) 0%,
      rgba(0, 101, 249, 1) 50%,
      rgba(0, 78, 193, 1) 100%
    );
    box-shadow: 0 0px 40px 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.02);
  }
}

.card-title {
  font-family: "Quicksand";
  font-size: 20px !important;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

.card__title__link {
  text-decoration: none;
  color: $primary;
  &:hover {
    color: white;
  }
}

.card__body3 {
  background-color: $light;
  padding: 0;
  border: 1px solid $secondary !important;
}

.card__body__4 {
  background-color: $light;
  border: 1px solid $primary;
  transition: 0.4s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  &:hover {
    background: white;
    box-shadow: 0 0px 40px 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.02);
  }
}

.card__title__link2 {
  text-decoration: none;
  color: $primary;
  &:hover {
    color: $primary;
  }
}
