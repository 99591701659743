.baris__ailesi__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /* The image used */
    background-image: url(../img/barisailesibg.jpg?as=webp);
    /* Set a specific height */
    min-height: 200px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .baris__ailesi {
      background-color: white;
      opacity: 0.8;
  }

  .baris__ailesi__text {
      font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    .baris__ailesi__text {
        font-size: 1.2rem;
   }
}
